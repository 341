import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ResetPassword from './pages/ResetPassword';
import LogIn from './pages/LogIn';
import UserInfo from './pages/UserInfo';
import { RecoilRoot } from 'recoil';
import Nav from './components/Nav';
import useUserInfo from './hooks/useUserInfo';
import { useUserInfoState } from './atoms/userState';
import tokenStorage from './lib/utils/tokenStorage';
import Home from './pages/Home';

const UserDataKeeper = () => {
  const {fetch} = useUserInfo();
  const [userInfo] = useUserInfoState();
  useEffect(() => {
    if(tokenStorage.get() && userInfo === null) {
      fetch();
    }
  }, [])
  return null;
}

function App() {
  return (
    <RecoilRoot>
      <UserDataKeeper />
      <Router>
        <AppBlock>
          <Nav />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/log-in">
              <LogIn />
            </Route>
            <Route path="/user-info">
              <UserInfo />
            </Route>
          </Switch>
        </AppBlock>
      </Router>
    </RecoilRoot>

  );
}

const AppBlock = styled.div`
`;
export default App;
