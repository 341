import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUserInfoState } from "../atoms/userState";

type Props = {
}

const Nav: FunctionComponent<Props> = ({ }) => {
    const [user] = useUserInfoState();
    return (
        <NavBlock>
            <div className="contents">
                <LogoLink to="/">ABLY</LogoLink>
                <ul>
                    <li>
                        <StyledLink to="/reset-password/request-token">Reset PW</StyledLink>
                    </li>
                    {user
                        ?
                        <li>
                            <StyledLink to="/user-info">My Info</StyledLink>
                        </li>

                        :
                        <li>
                            <StyledLink to="/log-in">LogIn</StyledLink>
                        </li>
                    }
                </ul>
            </div>
        </NavBlock>
    )
}

const NavBlock = styled.nav`
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1), 0 3px 3px rgba(0,0,0,0.1);
    >.contents {
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        height: 50px;

        >ul {
            height: 50px;
            display: flex;
            align-items: center;

            >li {
                padding: 0 10px;
                text-align: center;
            }
        }
    }
`;


const StyledLink = styled(Link)`
    text-decoration: none;
    color: gray;
    &:hover {
        color: black;
    }
`;

const LogoLink = styled(StyledLink)`
    color: black;
    line-height: 50px;
    font-weight: 700;
`;
export default Nav;