import axios from "axios";

export default async function login(email: string, password: string) {
    const response = await axios.post<{accessToken: string}>(
        'https://ably-frontend-interview-server.vercel.app/api/login',
        {
            email, password
        },
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    );

    console.log(`login response: `, response)
    return response.data;
}