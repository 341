import { userInfo } from 'node:os';
import React from 'react';
import styled from 'styled-components';
import { useUserInfoState } from '../../atoms/userState';
import MainHeader from '../../components/MainHeader';
import ArticleHeading from '../../components/styledComponents/ArticleHeading';
import useUserInfo from '../../hooks/useUserInfo';

type Props = {

}

const Home:React.FunctionComponent<Props> = ({}) => {
    const [userInfo] = useUserInfoState()
    return (
        <HomeBlock>
            <MainHeader title={`Hello ${userInfo ? userInfo.name : "Guest"}`}/>
            <article>
                <ArticleHeading>에이블리 홈 화면</ArticleHeading>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem consequuntur adipisci numquam magnam minima nam illo, mollitia dicta nostrum delectus maiores excepturi doloremque doloribus libero officia sed in voluptatum? Veniam.</p>
            </article>
        </HomeBlock>
    )
}

const HomeBlock = styled.main`
    max-width: 420px;
    margin: 100px auto;
`;

export default Home;