import { FunctionComponent } from "react";
import styled, { css, keyframes } from "styled-components";
import UserInfoCard from "./styledComponents/UserInfoCard";

type Props = {
}

const SkeletonUserInfo:FunctionComponent<Props> = ({}) => {
    return (
        <SkeletonUserInfoBlock>
            <div className="sklt-img img-wrapper" />
            <div className="sklt-text card-text"/>
            <div className="sklt-text card-text"/>
            <div className="sklt-button card-button" />
        </SkeletonUserInfoBlock>
    )
}

const Blink = keyframes`
    0% {
        background: #e4e4e4;
    }
    50% {
        background: #cacaca;
    }
    100% {
        background: #e4e4e4;
    }
`;

const BlinkCss = css`
    animation-name: ${Blink};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
`

const SkeletonUserInfoBlock = styled(UserInfoCard)`
    >* {
        ${BlinkCss};
    }
    
    >.sklt-img {}

    >.sklt-text {
        width: 150px;
        height: 21px;
    }

    >.sklt-button {
        height: 40px;
    }

`;


export default SkeletonUserInfo;