import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { useUserInfoState } from '../../atoms/userState';
import MainHeader from '../../components/MainHeader';
import useUserInfo from '../../hooks/useUserInfo';
import logout from '../../lib/api/logout';
import tokenStorage from '../../lib/utils/tokenStorage';
import catImg from '../../assets/cat_placeholder.png';
import FormErrorMessageBox from '../../components/styledComponents/FormErrorMessageBox';
import Button from '../../components/Button';
import SkeletonUserInfo from '../../components/SkeletonUserInfo';
import UserInfoCard from '../../components/styledComponents/UserInfoCard';
import useFetchReducer from '../../hooks/useFetchReducer';

type Props = {

}

const UserInfo: React.FunctionComponent<Props> = ({ }) => {
    const {fetch, error} = useUserInfo();
    const [userInfo, setInfo] = useUserInfoState();
    const history = useHistory();
    const location = useLocation();
    const [fetchState, dispatch] = useFetchReducer();

    const handleLogout = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
            await logout();
            dispatch({ type: 'FETCH_SUCCESS' });
            tokenStorage.clear();
            setInfo(null);
            history.replace('/log-in');
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    useEffect(() => {
        if(userInfo === null) {
          fetch();
        }
    }, [])

    useEffect(() => {
        if(error) {
            alert(error);
            setInfo(null)
            tokenStorage.clear();
            history.push('/log-in', {from: location});
        }
    }, [error])

    return (
        <UserInfoBlock>
            <MainHeader title="User Info" />
            {userInfo
                ?
                <UserInfoCard>
                    <div className="img-wrapper" style={{ backgroundImage: `url(${catImg})` }}>
                        <img src={userInfo.profileImage} alt="profile-image" />
                    </div>
                    <p className="card-text">Email: {userInfo.email}</p>
                    <p className="card-text">Name: {userInfo.name}</p>
                    <Button className="card-button" onClick={handleLogout} isLoading={fetchState.isLoading} disabled={fetchState.isLoading}>Log out</Button>
                    {fetchState.error && <FormErrorMessageBox>{fetchState.error}</FormErrorMessageBox>}
                </UserInfoCard>

                :
                <SkeletonUserInfo />
            }
        </UserInfoBlock>
    )
}

const UserInfoBlock = styled.main`
    max-width: 420px;
    margin: 100px auto;
`;

export default UserInfo;