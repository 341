import { atom, useRecoilState } from 'recoil';
import { UserType } from '../lib/api/getUserInfo';

export const userInfoState = atom<UserType | null>({
    key: 'userInfoState',
    default: null
})

export function useUserInfoState() {
    return useRecoilState(userInfoState);
}