import { useUserInfoState } from "../atoms/userState";
import getUserInfo from "../lib/api/getUserInfo";
import useFetchReducer from "./useFetchReducer";

export default function useUserInfo() {
    const [, setInfo] = useUserInfoState();
    const [fetchState, dispatch] = useFetchReducer();
    const fetchdata = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
            const data  = await getUserInfo();
            dispatch({ type: 'FETCH_SUCCESS' });
            setInfo(data);
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    return {
        isLoading: fetchState.isLoading,
        error: fetchState.error,
        fetch: fetchdata
    };
}