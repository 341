import React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';
import MainHeader from '../../components/MainHeader';
import ChangePassword from './ChangePassword';
import Complate from './Complate';
import RequestToken from './RequestToken';
import ValidateToken from './ValidateToken';

type Props = {

}

const ResetPassword:React.FunctionComponent<Props> = ({}) => {
    return (
        <ResetPasswordBlock>
            <MainHeader title="Reset Password" />
            <Switch>
                <Route path="/reset-password/request-token">
                    <RequestToken />
                </Route>
                <Route path="/reset-password/validate-token">
                    <ValidateToken />
                </Route>
                <Route path="/reset-password/change-password">
                    <ChangePassword />
                </Route>
                <Route path="/reset-password/complate">
                    <Complate />
                </Route>
            </Switch>
        </ResetPasswordBlock>
    )
}

const ResetPasswordBlock = styled.main`
    max-width: 420px;
    margin: 100px auto;
`;

export default ResetPassword;