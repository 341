import axios from "axios";
import tokenStorage from "../utils/tokenStorage";

export type UserType = {
    email: string;
    lastConnectedAt: number;
    name: string;
    password: string;
    profileImage: string;
}

export default async function getUserInfo() {
    const response = await axios.get<UserType>(
        'https://ably-frontend-interview-server.vercel.app/api/user',
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStorage.get()}`
            }
        }
    );

    console.log(`getUserInfo response: `, response)
    return response.data;
}
