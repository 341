import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import Button from '../../components/Button';
import MainHeader from '../../components/MainHeader';
import FormErrorMessageBox from '../../components/styledComponents/FormErrorMessageBox';
import StyledInput from '../../components/styledComponents/StyledInput';
import useFetchReducer from '../../hooks/useFetchReducer';
import login from '../../lib/api/login';
import tokenStorage from '../../lib/utils/tokenStorage';

type Props = {

}

const LogIn:React.FunctionComponent<Props> = ({}) => {
    const history = useHistory();
    const location = useLocation<{from: Location | null}>();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fetchState, dispatch] = useFetchReducer();

    const handleChangeEmail:React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setEmail(value);
    }
    const handleChangePassword:React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setPassword(value);
    }
    const handleSubmit:React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        dispatch({ type: 'FETCH_INIT' });
        try {
            const data = await login(email, password);
            dispatch({ type: 'FETCH_SUCCESS' });
            tokenStorage.set(data.accessToken);
            
            if(location.state?.from) {
                history.replace(location.state.from.pathname);
            } else {
                history.replace("/user-info");
            }

        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    useEffect(() => {
        //NOTE: 이미 토큰이 있는 경우 user-info 페이지로 이동
        //NOTE: 만약 유효하지 않은 토큰인 경우 user-info에서 localStorage에 있는 토큰 삭제 후, log-in page로 이동.
        if(tokenStorage.get()) { 
            history.replace("/user-info");
        }
    }, [])
    return (
        <LogInBlock>
            <MainHeader title="Log In"/>
            <LoginForm onSubmit={handleSubmit}>
                <StyledInput type="email" value={email} onChange={handleChangeEmail} placeholder="Email"/>
                <StyledInput type="password" value={password} onChange={handleChangePassword} placeholder="Password"/>
                <Button type="submit" isLoading={fetchState.isLoading} disabled={fetchState.isLoading}>Log In</Button>
                {fetchState.error && <FormErrorMessageBox>{fetchState.error}</FormErrorMessageBox>}
            </LoginForm>
        </LogInBlock>
    )
}

const LogInBlock = styled.main`
    max-width: 420px;
    margin: 100px auto;
`;

const LoginForm = styled.form`
    
    display: flex;
    flex-direction: column;
    

    >* {
        margin-bottom: 20px;
    }
`;

export default LogIn;