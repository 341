const KEY = "accessToken";

const tokenStorage = {
    get() {
        const token = window.localStorage.getItem(KEY);
        if(token) {
            return token;
        } else {
            return null;
        }
    },
    set(token: string) {
        window.localStorage.setItem(KEY, token);
    },
    clear() {
        window.localStorage.removeItem(KEY);
    }
}

export default tokenStorage;