import axios from "axios";

export default async function validateToken(token: string) {
    const response = await axios.post<{
        confirmToken: string;
    }>(
        `https://ably-frontend-interview-server.vercel.app/api/reset-password`,
        {
            email: "ably368@dummy.com",
            authCode: "171009",
            issueToken: token
        },
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    )
    console.log(`validateToken response: `, response);
    return response.data;
}