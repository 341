import axios from "axios";
import tokenStorage from "../utils/tokenStorage";

export default async function logout() {
    const response = await axios.post<{lastConnectedAt: Date}>(
        'https://ably-frontend-interview-server.vercel.app/api/logout',
        {},
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStorage.get()}`
            }
        }
    );

    console.log(`logout response: `, response)
    return response.data;
}