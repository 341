import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useConfirmTokenState, useIssueTokenState } from '../../../atoms/authState';
import Button from '../../../components/Button';
import ArticleHeading from '../../../components/styledComponents/ArticleHeading';
import FormErrorMessageBox from '../../../components/styledComponents/FormErrorMessageBox';
import StyledInput from '../../../components/styledComponents/StyledInput';
import useExpireTimeCounter from '../../../hooks/useExpireTimeCounter';
import useFetchReducer from '../../../hooks/useFetchReducer';
import validateToken from '../../../lib/api/resetPassword/validateToken';

type Props = {

}

const RequestToken: React.FunctionComponent<Props> = ({ }) => {
    const history = useHistory();
    const [issueToken] = useIssueTokenState();
    const [token, setToken] = useState(issueToken ?? "");
    const [, setConfirmToken] = useConfirmTokenState();
    const {remainTime, isExpired} = useExpireTimeCounter(); //NOTE: 만료시간 Counter
    const [fetchState, dispatch] = useFetchReducer();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setToken(value);
    }
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        dispatch({ type: 'FETCH_INIT' });
        try {
            const data = await validateToken(token);
            dispatch({ type: 'FETCH_SUCCESS' });
            setConfirmToken(data.confirmToken);
            history.replace('/reset-password/change-password');
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: {error} });
        }
    }

    return (
        <RequestTokenBlock>
            <ArticleHeading>
                인증 코드 검증
            </ArticleHeading>
            <Form onSubmit={handleSubmit}>
                <InputWrapper>
                    <StyledInput type="text" value={token} onChange={handleChange} placeholder="인증 코드"/>
                    <RemainTimeBox>{isExpired ? "시간만료" : remainTime}</RemainTimeBox>
                </InputWrapper>
                <Button type="submit" isLoading={fetchState.isLoading} disabled={fetchState.isLoading}>인증하기</Button>
            </Form>
            {fetchState.error && <FormErrorMessageBox>{fetchState.error}</FormErrorMessageBox>}
        </RequestTokenBlock>
    )
}

const RequestTokenBlock = styled.article`

`;

const Form = styled.form`
    display: flex;
    >div {
        width: 100%;
    }
    >button {
        flex: 1 0 30%;
    }
`;
const InputWrapper = styled.div`
    position: relative;
    >input {
        width: 100%;
    }
`;

const RemainTimeBox = styled.p`
    position: absolute;
    top: 10px;
    right: 10px;

    color: red;
`;

export default RequestToken;