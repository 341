import styled from "styled-components";

const UserInfoCard = styled.div`
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    >.img-wrapper {
        width: 250px;
        height: 250px;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 250px;
        >img {
            width: 100%;
            height: 100%;
            border-radius: 250px;
        }
    }

    >.card-text {
        font-size: 16px;
        margin-bottom: 10px;
    }

    >.card-button {
        width: 100px;
        margin-top: 20px;
    }
    
`;

export default UserInfoCard;